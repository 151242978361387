import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import App from '../App.vue';

Vue.use(VueRouter);
Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/../App.vue',
    name: 'App',
    component: App,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/Resume',
    name: 'Resume',
    component: () => import('../views/Resume.vue'),
  },
  {
    path: '/Projects',
    name: 'Projects',
    component: () => import('../views/Projects.vue'),
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;

/* Form Data Collection */
