<template>
  <!-- eslint-disable max-len -->
  <!-- eslint-disable -->
  <div class="home">
    <div class="profile-container">
      <div class="profile-header">
        <div class="profile-img">
          <img src="../assets/Headshot.jpeg" alt="Zachariah Reid" />
        </div>
      </div>
      <div class="profile-details">
        <h2>Hi, I'm Zachariah.</h2>
        <h1>I bring dreams to life through web development.</h1>
        <h2>I am a FrontEnd Developer based in Cleveland, OH.</h2>
        <a @click="scrollMeTo('about')">
          <button class="btn-primary">
          Learn More
            <span class="icon"
              ><font-awesome-icon icon="chevron-right" size="1x"
            /></span>
          </button>
        </a>
      </div>
    </div>
    <div class="about-container" ref="about">
      <span></span>
      <h1>About</h1>
      <div class="about">
        <div class="paragraph">
          <p>
            I love fashion. My style is a big part of who I am and I believe it speaks to the type of person that I am. I like to think that my web development style is also indicative of the style that I like to maintain for myself. Ensuring that each person that I work with is able to walk away with a site that they are proud of is essential to my work. While keeping your vision at the forefront I make it my business to collaborate with you in the best and most unique way.
          </p>
          <p>
            I have studied Web Development at one of the best engineering schools in the country, Syracuse University. I have also spent a portion of my time after graduating as a Web Content Producer at SiDEARM Sports. I have also spent some time at this company as a FrontEnd Devloper where I was able to work on my HTML, CSS, and Javascript skills as I created websites for collegiate athletic teams all over the country.
          </p>
          <p>
            This is a passion for me. I love the work that I do and the websites that I am able to create. I look forward to working with you and seeing what amazing sites we are able to create! Speak soon. <br><br>
            - <i>Zachariah Reid</i>
          </p>
        </div>
      </div>
      <router-link @click.native="$scrollToTop" to="/Resume">
        <button class="btn-secondary">
          Resume
          <span class="icon"><font-awesome-icon icon="chevron-right" size="1x"/></span>
        </button>
      </router-link>
    </div>
    <div class="skills-container" id="skills">
      <h1>Tech Stack</h1>
      <div class="skill-tile-container">
        <ul id="skill-menu">
          <li v-for="skill in skills" class="skill-tile-item">{{ skill }}</li>
        </ul>
      </div>
    </div>
    <div class="pricing-container">
      <h1>Pricing</h1>
      <div class="pricing-tile-container">
        <div class="pricing-tile-item">
          <h2>Web Page</h2>
          <ul>
            <li>Free consultation</li>
            <li>Unlimited Sections (in addition to navigation and footer)</li>
            <li>2 free revisions</li>
            <li>$60 per section</li>
          </ul>
          <router-link @click.native="$scrollToTop" to="/Contact">
            <button class="btn-tertiary">
              Contact Me
            </button>
          </router-link>
        </div>
        <div class="pricing-tile-item">
          <h2>Web Site</h2>
          <ul>
            <li>Free consultation</li>
            <li>Unlimited Sections (in addition to navigation and footer)</li>
            <li>4 free revisions</li>
            <li>*Price determined during consultation</li>
          </ul>
          <router-link @click.native="$scrollToTop" to="/Contact">
            <button class="btn-tertiary">
              Contact Me
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../views/home.scss";
</style>
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
/* export default {
  views: {
    'resume': resume,
  },
}; */
export default {
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = element.offsetTop;
      window.scrollTo(0, top);
    },
  },
  data() {
    return {
      skills: [
        'VueJs',
        'ReactJs',
        'Vanilla JS',
        '.NET',
        'Python',
        'C#',
        'HTML5',
        'TailWind CSS',
        'SCSS',
        'CSS',
        'PostgresSQL',
        'Microsoft SQl Server',
      ],
    };
  },
};
</script>
