import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
  faTwitter, faLinkedin, faInstagram, faTiktok, faJsSquare, faCss3, faHtml5,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import firebase from 'firebase';
import App from './App.vue';
import router from './router';
import store from './store';

library.add(
  faBars, faChevronRight, faTwitter, faLinkedin,
  faInstagram, faTiktok, faHtml5, faCss3, faJsSquare,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: 'AIzaSyDgVLnvhDkMzYw8bxstvueE6LLyPJnTqc8',
  authDomain: 'contact-forms-a7efa.firebaseapp.com',
  projectId: 'contact-forms-a7efa',
  storageBucket: 'contact-forms-a7efa.appspot.com',
  messagingSenderId: '13840105518',
  appId: '1:13840105518:web:ea9a66a51b8a2c6fc51fbf',
  measurementId: 'G-TSVYK1CFPQ',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const projectFirestore = firebase.firestore();

export default projectFirestore;

new Vue({
  router,
  store,
  render: (h) => h(App),
  el: '#app',
  data: {
    fname: '',
    lname: '',
    email: '',
    phone: '',
    company: '',
    request: '',
  },
  methods: {
    getFormValues(submitEvent) {
      this.fname = submitEvent.target.elements.name.value;
      this.lname = submitEvent.target.elements.name.value;
      this.email = submitEvent.target.elements.name.value;
      this.phone = submitEvent.target.elements.name.value;
      this.company = submitEvent.target.elements.name.value;
      this.request = submitEvent.target.elements.name.value;
    },
  },
}).$mount('#app');

/* $('#sub-btn').click(function(event){
  event.preventDefault();
  $('#sub-navigation').toggleClass('.show-subnav');
}) */
