<template>
  <!-- eslint-disable max-len -->
  <div id="app">
    <nav>
      <div class="main-nav">
        <div id="nav-logo">
          <router-link @click.native="$scrollToTop" to="/"><img src="./assets/my_logo_white.png" alt="Site Logo" /></router-link>
        </div>
        <div id="nav">
          <router-link @click.native="$scrollToTop" to="/Resume" class="resume">Resume</router-link>
          <router-link to="/contact">Contact</router-link>
        </div>
        <div class="responsive-nav">
          <div id="nav-icon" class="nav-icon">
            <button class="icon" id="sub-btn" v-on:click="active = !active">
              <font-awesome-icon icon="bars" size="1x" />
            </button>
          </div>
        </div>
      </div>
      <div class="responsive-items" id="sub-navigation" v-if="active">
        <ul class="nav-items">
          <li v-on:click="active = !active"><router-link to="/Contact">Contact</router-link></li>
          <li v-on:click="active = !active"><router-link to="/Resume">Resume</router-link></li>
        </ul>
      </div>
    </nav>
    <router-view />
    <footer>
      <img src="./assets/my_logo_white.png" alt="Zachariah Reid logo" />
      <div class="social-media-container">
        <a href="https://twitter.com/_zachsr_"><span class="icon"
          ><font-awesome-icon
            :icon="{ prefix: 'fab', iconName: 'twitter' }"
            size="2x"
        /></span></a>
        <a href = "http://www.linkedin.com/in/zachariah-reid-747639122" ><span class="icon"
          ><font-awesome-icon
            :icon="{ prefix: 'fab', iconName: 'linkedin' }"
            size="2x"
        /></span></a>
        <a href = "https://www.instagram.com/official_zsr/"><span class="icon"
          ><font-awesome-icon
            :icon="{ prefix: 'fab', iconName: 'instagram' }"
            size="2x"
        /></span></a>
        <a href="https://www.tiktok.com/@official_ztr"><span class="icon"
          ><font-awesome-icon
            :icon="{ prefix: 'fab', iconName: 'tiktok' }"
            size="2x"
        /></span></a>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
@import './views/nav.scss';
#app {
  //@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
  //@import url("https://use.typekit.net/vha5rhj.css");
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //color: #2c3e50;
}
nav{
  //position:fixed;
  width:100%;
  z-index: 10000;
}
</style>

<script>

export default ({
  data() {
    return {
      active: false,
    };
  },
});
</script>
